import type { LineItem } from "~/services/roma-api/cart/types-lineItems";
import type { OrderProductTypes } from "~/services/roma-api/cart/types-productOrdering";

export const convertLineItemToOrderItem = (
  line: LineItem
): OrderProductTypes => {
  const {
    ID,
    CalculatedQuantity,
    Amount,
    SubTotal,
    Discount,
    Tax,
    NotesInternal,
    NotesExternal,
    ...rest
  } = line;

  // return all the properties of the LineItem *except* the cart-specific..
  // TODO test on complex line item like stack
  return rest;
};