import {
  FlowComponent,
  Show,
  For,
  JSXElement,
  createSignal,
  type ComponentProps,
} from "solid-js";
import { useNavigate } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { DropdownMenu, InteractOutsideEvent } from "@kobalte/core";
import "./lineItemMenu.css";

type DropdownProps = ComponentProps<typeof DropdownMenu.Root>;

type MenuItem = {
  label: string | JSXElement;
  disabled?: boolean;
  new?: boolean;
  beta?: boolean;
  icon?: { path: JSXElement } | JSXElement;
  newTab?: boolean;
} & ({ href: string } | { onSelect: () => void });

export type PanelItemMenuGroup = {
  label?: string | JSXElement;
  list: MenuItem[];
};

type PanelItemMenuProps = {
  list: PanelItemMenuGroup;
  onOpenChange?: (open: boolean) => void;
  footer?: string | JSXElement;
  gutter?: DropdownProps["gutter"];
  placement?: DropdownProps["placement"];
  triggerClass?: string;
  triggerOpenClass?: string;
};

export const PanelItemMenu: FlowComponent<PanelItemMenuProps> = (props) => {
  const [open, setOpen] = createSignal(false);
  const navigate = useNavigate();

  const closeMenu = () => void setOpen(false);

  const manualCloseMenuFns = {
    onEscapeKeyDown: closeMenu,
    onPointerDownOutside: closeMenu,
    onInteractOutside: (e: InteractOutsideEvent) => {
      e.preventDefault();
      closeMenu();
    },
  };

  const itemClass =
    "text-sm cursor-pointer rounded-[4px] flex items-center px-1 relative select-none outline-none h-8 data-[highlighted]:bg-roma-blue data-[highlighted]:text-white data-[disabled]:text-roma-medium-grey data-[disabled]:pointer-events-none group whitespace-nowrap ";

  const contentClass =
    "min-w-[120px] p-2 rounded-md border border-gray-300 shadow-md outline-none bg-roma-grey z-50  max-h-[85vh] overflow-y-auto ";

  const newPill =
    "flex bg-roma-blue text-white group-hover:bg-white group-hover:text-roma-blue justify-center items-center text-[10px] leading-snug rounded-full px-2 ml-3 ";

  return (
    <DropdownMenu.Root
      open={open()}
      gutter={props.gutter ?? 2}
      placement={props.placement ?? "left"}
      modal={false}
      preventScroll={false}
      hideWhenDetached
      {...props}
    >
      <DropdownMenu.Trigger
        classList={{
          [`${props.triggerClass}`]: !!props.triggerClass,
          [`${props.triggerOpenClass}`]: open() && !!props.triggerOpenClass,
          "bg-roma-blue rounded-r-md !text-white border-l-2 border-white":
            open() && !props.triggerOpenClass,
        }}
        onClick={() => setOpen(!open())}
      >
        {props.children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          class={`dropdown-menu__content ${contentClass}`}
          {...manualCloseMenuFns}
        >
          <For each={props.list.list}>
            {(item) => (
              <DropdownMenu.Item
                disabled={item.disabled}
                class={itemClass}
                onSelect={() => {
                  if ("onSelect" in item && item.onSelect) {
                    item.onSelect();
                  } else if ("href" in item && item.href && !item.newTab) {
                    navigate(item.href);
                  } else if ("href" in item && item.href && item.newTab) {
                    window.open(item.href, "_blank", "rel=noopener noreferrer");
                  }
                }}
              >
                <Show when={item.icon}>
                  <Icon
                    path={item.icon as IconPath}
                    class="w-5 mr-2 shrink-0"
                  />
                </Show>
                {item.label}
                <Show when={item.new && !item.disabled}>
                  <div class={newPill}>New</div>
                </Show>
                <Show when={item.beta && !item.disabled}>
                  <div class={newPill + "!bg-orange-600"}>Beta</div>
                </Show>
              </DropdownMenu.Item>
            )}
          </For>

          <Show when={props.footer}>
            <DropdownMenu.Separator class="dropdown-menu__separator" />
            <div class="px-6 py-2">{props.footer}</div>
          </Show>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
