import { Component, createSignal, createEffect, onCleanup, Show } from "solid-js";
import { Icon } from "solid-heroicons";
import { xMark } from "solid-heroicons/outline";
import type { ErrorNotification } from "~/utils/contexts";

type DismissButtonProps = {
  notification: ErrorNotification;
  onDismiss: () => void;
  isPaused: boolean;
};

export const DismissButton: Component<DismissButtonProps> = (props) => {
  const [progress, setProgress] = createSignal(100);
  const [isHovered, setIsHovered] = createSignal(false);
  
  const radius = 12;
  const circumference = 2 * Math.PI * radius;

  createEffect(() => {
    if (!props.notification.autoDisappear || props.isPaused || isHovered()) return;
    
    const startTime = Date.now();
    
    const updateProgress = () => {
      const elapsed = Date.now() - startTime;
      const remaining = Math.max(0, props.notification.autoDisappearDelay! - elapsed);
      const newProgress = (remaining / props.notification.autoDisappearDelay!) * 100;
      
      setProgress(newProgress);
      
      if (newProgress > 0) {
        requestId = requestAnimationFrame(updateProgress);
      } else {
        props.onDismiss();
      }
    };
    
    let requestId = requestAnimationFrame(updateProgress);
    
    onCleanup(() => {
      cancelAnimationFrame(requestId);
    });
  });
  
  // createEffect(() => {
  //   if (!props.notification.autoDisappear || props.isPaused || isHovered()) return;
    
  //   const startTime = Date.now();
  //   let pausedTime = 0;
    
  //   const updateProgress = () => {
  //     const elapsed = Date.now() - startTime - pausedTime;
  //     const remaining = Math.max(0, props.notification.autoDisappearDelay! - elapsed);
  //     const newProgress = (remaining / props.notification.autoDisappearDelay!) * 100;
      
  //     setProgress(newProgress);
      
  //     if (newProgress > 0) {
  //       requestId = requestAnimationFrame(updateProgress);
  //     } else {
  //       props.onDismiss();
  //     }
  //   };
    
  //   let requestId = requestAnimationFrame(updateProgress);
    
  //   onCleanup(() => {
  //     cancelAnimationFrame(requestId);
  //   });
  // });
  
  return (
    <button
      class="relative p-1 hover:bg-white/10 rounded active:translate-y-0.5 group"
      onClick={props.onDismiss}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon path={xMark} class="w-5 h-5 relative z-10" />
      
      <Show when={props.notification.autoDisappear}>
        <svg 
          class="absolute inset-0 -rotate-90 opacity-70 "
          width="28" 
          height="28" 
          viewBox="0 0 28 28"
        >
          <circle
            cx="14"
            cy="14"
            r={radius}
            stroke="white"
            stroke-width="1.5"
            fill="none"
            stroke-dasharray={circumference.toString()}
            stroke-dashoffset={circumference - (circumference * progress() / 100)}
            stroke-linecap="round"
          />
        </svg>
        
        {/* <Show when={isHovered()}>
          <div class="absolute bottom-full right-0 mb-1 px-2 py-1 text-xs bg-black/80 rounded whitespace-nowrap">
            Auto-dismissing in {Math.ceil((props.notification.autoDisappearDelay! * progress()) / (100 * 1000))}s
            {props.isPaused && " (paused)"}
          </div>
        </Show> */}
      </Show>
    </button>
  );
};
