import { Component } from "solid-js";
import { BaseLoader } from "~/components/utility";

export const PanelLoadingBlocker: Component = () => {
  return (
    <div class="visible transition-all absolute inset-x-0 inset-y-0 backdrop-blur-[2px] pointer-events-none !border-t-0 z-50 bg-white/50 flex justify-center items-center">
      <BaseLoader width={8} height={8} />
    </div>
  );
};
