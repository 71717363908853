import {
  Component,
  Show,
  createEffect,
  Setter,
  For,
  Accessor,
  InitializedResource,
  Resource,
  Suspense,
} from "solid-js";
import { type AccessorWithLatest } from "@solidjs/router";

// import { Motion, Presence } from "@motionone/solid";
import { Motion, Presence } from "solid-motionone";
import { SearchSuggestions, ProductResults, PageResults } from "./results";
import { type SearchResponse } from "~/utils/searchLogic";

const dummySuggestions = [
  {
    groupName: "Recent Searches",
    list: [],
  },
  {
    groupName: "Popular",
    list: [
      {
        label: "New Mouldings",
        href: "/shop?category=New Releases",
        type: "link",
      },
      {
        label: "Icon Collection",
        href: "/shop?collection=Icon&sort=is_new",
        type: "link",
      },
      {
        label: "Made in Italy",
        href: "/about/made-in-italy",
        type: "link",
      },
      {
        label: "Roma Wish",
        href: "/about/roma-wish",
        type: "link",
      },
    ],
  },
];

type SearchResultsProps = {
  searchResults: AccessorWithLatest<SearchResponse>;
  setSearchValue: Setter<string | undefined>;
  searchValue: Accessor<string | undefined>;
  // suggestions: any;
};

export const SearchResults: Component<SearchResultsProps> = (props) => {

  return (
    <Motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.25,
          delay: 0,
        },
      }}
      transition={{ duration: 0.25, delay: 0.4 }}
      class="grid md:grid-cols-[200px,_1fr] overflow-y-auto max-w-full border-t"
    >
      <Show
        when={
          !!props.searchValue() &&
          props?.searchResults?.latest?.MouldingTotal === 0 &&
          props.searchResults?.latest?.BuiltFrames.Total === 0 &&
          props.searchResults?.latest?.Pages.Total === 0 
        }
      >
        <div class="col-span-full max-w-xl mx-auto flex justify-between pt-2">
          <div class="mx-2 text-center bg-roma-grey rounded-lg p-8 basis-1/2 shrink-0 grow">
            We couldn't find what you were looking for, please try another
            search term, or try one of the links below
          </div>
        </div>
      </Show>
      <div
        class={`group ${
          props.searchResults?.latest?.MouldingTotal > 0 ||
          props.searchResults?.latest?.Pages.Total > 0
            ? ""
            : "col-span-full flex justify-center"
        }`}
        data-no-results={
          props.searchResults?.latest?.MouldingTotal > 0 ||
          props.searchResults?.latest?.Pages.Total > 0
            ? undefined
            : true
        }
      >
        <SearchSuggestions
          list={dummySuggestions}
          setSearchValue={props.setSearchValue}
        />
      </div>
      <div class="md:border-l h-full   grow md:grow-0 max-w-full divide-y md:overflow-y-auto overflow-x-auto  flex flex-col ">
        <Suspense>
          <Presence exitBeforeEnter>
            <Show when={props.searchResults?.latest?.Mouldings.Total > 0}>
              <ProductResults
                title={`Mouldings (${props.searchResults?.latest?.Mouldings.Total})`}
                products={props.searchResults?.latest?.Mouldings}
              />
            </Show>
          </Presence>
        </Suspense>
        <Suspense>
          <Presence exitBeforeEnter>
            <Show when={props.searchResults?.latest?.BuiltFrames.Total > 0}>
              <ProductResults
                title={`Photo & Gallery Frames (${props.searchResults?.latest?.BuiltFrames.Total})`}
                products={props.searchResults?.latest?.BuiltFrames}
              />
            </Show>
          </Presence>
        </Suspense>
        <Presence exitBeforeEnter>
          <Show when={props.searchResults?.latest?.Pages.Total > 0}>
            <PageResults
              title={`Pages & Information (${props.searchResults?.latest?.Pages.Total})`}
              pages={props.searchResults?.latest?.Pages}
            />
          </Show>
        </Presence>
      </div>
      {/* </div> */}
    </Motion.div>
  );
};
