import { Accessor, Component, onMount } from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { Icon } from "solid-heroicons";
import { magnifyingGlass } from "solid-heroicons/outline";
import { useSessionContext, useSiteContext } from "~/utils/contexts";

type SearchBarProps = {
  onInput?: (val: string) => void;
  searchValue: Accessor<string | undefined>;
};

export const SearchBar: Component<SearchBarProps> = (props) => {
  const { recentlySearched } = useSiteContext();
  let searchRef: HTMLInputElement;
  const addSearch = debounce((val: string) => recentlySearched.add(val), 1500);

  onMount(() => searchRef.focus());
  return (
    <div class="md:max-w-3xl relative rounded-full bg-white border px-3 py-1 shadow-sm focus-within:ring-1 ring-roma-dark-grey focus-within:z-10 grow group flex items-center">
      <input
        id="search-input"
        name="search"
        ref={(ref) => (searchRef = ref)}
        autocomplete="off"
        type="text"
        class="block w-full border-0 p-0 pl-3 transition duration-500 placeholder:pl-3 max-sm:placeholder:text-xs  placeholder-gray-500 sm:text-sm outline-none disabled:bg-white focus:outline-none"
        placeholder="Search by moulding, color, style, etc"
        onInput={(e) => {
          if (props.onInput) props.onInput(e.currentTarget.value);
          addSearch(e.currentTarget.value.trim());
        }}
        value={props.searchValue() ? props.searchValue() : ""}
      />
      <div class="absolute right-8 w-8 bg-gradient-to-r from-white/0 to-white/100 h-full"/>
      <Icon
        path={magnifyingGlass}
        class="w-6  group-focus-within:text-roma-blue z-10"
      />
    </div>
  );
};
