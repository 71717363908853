import type { Component } from "solid-js";
import { BaseLoader } from "~/components/utility";

export const PanelItemsSkeleton: Component<{ class?: string }> = (props) => {
  return (
    <div
      class="grow flex flex-col text-center items-center justify-center  bg-gray-200 my-4 rounded-lg animate-pulse"
      classList={{ [`${props.class}`]: !!props.class }}
    >
      <BaseLoader width={8} height={8} />
    </div>
  );
};
