import { createSignal } from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { gql } from "@solid-primitives/graphql";
import algoliasearch from "algoliasearch";
import { query, createAsync } from "@solidjs/router";
// import { AlgoliaMouldingResult } from "../ecommerce/ordering/comps/ProductSearch";
import { AlgoliaMouldingResult } from "./types";
import { GALLERYFRAME, PHOTOFRAME, MOULDING } from "~/utils/products";
// import { SiteContext } from "~/utils/contexts";
import { useSiteContext } from "./contexts";
import { EventType } from "@solid-primitives/analytics";

export type ResultGroup = {
  Results: any[] | [];
  Total: number;
  Errors: null | string[];
};

export type SearchResponse = {
  Mouldings: ResultGroup;
  PhotoFrames: ResultGroup;
  GalleryFrames: ResultGroup;
  BuiltFrames: ResultGroup;
  Pages: ResultGroup;
  MouldingTotal: number;
};

export const searchLogic = () => {
  const { track } = useSiteContext();
  const [searchValue, setSearchValue] = createSignal<string>("");
  // Algolia Product Search
  const client = algoliasearch(
    import.meta.env.VITE_ALGOLIA_APP_ID,
    import.meta.env.VITE_ALGOLIA_API_KEY
  );
  const index = client.initIndex(import.meta.env.VITE_ALGOLIA_INDEX);
  // Algolia Pages Search
  const client_pages = algoliasearch(
    import.meta.env.VITE_ALGOLIA_APP_ID_PAGES,
    import.meta.env.VITE_ALGOLIA_API_KEY_PAGES
  );
  const index_pages = client_pages.initIndex(
    import.meta.env.VITE_ALGOLIA_INDEX_PAGES
  );
  const search = debounce((value: string) => {
    // track(EventType.Event, {
    //   category: "search",
    //   action: "user_searched",
    //   value: value,
    // });
    setSearchValue(value);
  }, 500);

  const getProductsAlgolia = async (
    val: string,
    options: { limit?: number; attributes?: string[] } = {}
  ): Promise<{
    Mouldings: ResultGroup;
    PhotoFrames: ResultGroup;
    GalleryFrames: ResultGroup;
    BuiltFrames: ResultGroup;
  }> => {
    const {
      limit = 250,
      attributes = [
        "sku",
        "name",
        "width",
        "height",
        "rabbet",
        "category",
        "collection",
        "color_description",
        "available_as",
        "discontinued",
        "is_floater",
        "product_type",
        "coming_soon",
        "arrival_date",
        "is_new",
        "on_sale",
      ],
    } = options;
    try {
      const response = await index.search<AlgoliaMouldingResult>(val, {
        hitsPerPage: limit,
        filters: "active:true",
        attributesToRetrieve: attributes,
      });
      // track(EventType.Event, {
      //   category: "search",
      //   action: "selected_results",
      //   value: response.hits.length.toString(),
      // });

      // * currently sorting the GF/PF individually as well as in
      // * a 'BuiltFrames' bucket depending on how they will be,
      // * used. Cleanup when determined

      const sorted = response.hits.reduce(
        (bucket, item: AlgoliaMouldingResult) => {
          const formatted = {
            SKU: item.objectID,
            Width: item.width,
            Height: item.height,
            Rabbet: item.rabbet,
            Collection: item.collection,
            Category: item.category,
            ColourDescription: item.color_description,
            AvailableAs: item.available_as,
            Discontinued: item.discontinued,
            Floater: item.is_floater,
            ProductType: item.product_type,
            DisplayType: MOULDING,
            ComingSoon: item.coming_soon,
            ArrivalDate: item.arrival_date,
            IsNew: item.is_new,
            OnSale: item.on_sale,
          };

          bucket.Mouldings.Results.push({ ...formatted });
          bucket.Mouldings.Total += 1;

          if (item.available_as.includes(GALLERYFRAME)) {
            const copy = { ...formatted, DisplayType: GALLERYFRAME };
            bucket.GalleryFrames.Results.push(copy);
            bucket.BuiltFrames.Results.push(copy);
            bucket.GalleryFrames.Total += 1;
            bucket.BuiltFrames.Total += 1;
          }
          if (item.available_as.includes(PHOTOFRAME)) {
            const copy = { ...formatted, DisplayType: PHOTOFRAME };
            bucket.PhotoFrames.Results.push(copy);
            bucket.BuiltFrames.Results.push(copy);
            bucket.PhotoFrames.Total += 1;
            bucket.BuiltFrames.Total += 1;
          }

          return bucket;
        },
        {
          Mouldings: { Results: [] as any[], Total: 0, Errors: null },
          PhotoFrames: { Results: [] as any[], Total: 0, Errors: null },
          GalleryFrames: { Results: [] as any[], Total: 0, Errors: null },
          BuiltFrames: { Results: [] as any[], Total: 0, Errors: null },
        }
      );

      return sorted;
    } catch (error) {
      console.log(error);
      return {
        Mouldings: {
          Results: [],
          Total: 0,
          Errors: ["Error retrieving product results"],
        },
        PhotoFrames: {
          Results: [],
          Total: 0,
          Errors: ["Error retrieving product results"],
        },
        GalleryFrames: {
          Results: [],
          Total: 0,
          Errors: ["Error retrieving product results"],
        },
        BuiltFrames: {
          Results: [],
          Total: 0,
          Errors: ["Error retrieving product results"],
        },
      };
    }
  };

  const getPages = async (
    val: string,
    options: { limit?: number; attributes?: string[] } = {}
  ): Promise<ResultGroup> => {
    const { limit = 10, attributes = ["url", "title", "description"] } =
      options;
    try {
      const response = await index_pages.search(val, {
        hitsPerPage: limit,
        attributesToRetrieve: attributes,
      });
      return {
        Total: response.hits.length,
        Results: response.hits,
        Errors: null,
      };
    } catch (error) {
      console.log(error);
      return {
        Total: 0,
        Results: [],
        Errors: ["Error retrieving page results"],
      };
    }
  };

  const fetchSearchResults = async (val: string) => {
    const results = await Promise.all([getProductsAlgolia(val), getPages(val)]);

    return {
      Mouldings: results[0].Mouldings,
      PhotoFrames: results[0].PhotoFrames,
      GalleryFrames: results[0].GalleryFrames,
      BuiltFrames: results[0].BuiltFrames,
      Pages: results[1],
      MouldingTotal:
        results[0].Mouldings.Total +
        results[0].PhotoFrames.Total +
        results[0].GalleryFrames.Total,
    };
  };

  const getSearchResults = query(async (val) => {
    if (!val || val === "") return;
    return await fetchSearchResults(val);
  }, "search-results");

  const initialValue = {
    Mouldings: { Results: [], Total: 0, Errors: null },
    PhotoFrames: { Results: [], Total: 0, Errors: null },
    GalleryFrames: { Results: [], Total: 0, Errors: null },
    BuiltFrames: { Results: [], Total: 0, Errors: null },
    Pages: { Results: [], Total: 0, Errors: null },
    MouldingTotal: 0,
  } as SearchResponse;

  const searchResults = createAsync(() => getSearchResults(searchValue()), {
    initialValue: initialValue,
  });

  return {
    searchValue,
    setSearchValue,
    search,
    searchResults,
    // suggestions,
  };
};
