import { createEffect, createSignal, For, Show, onCleanup } from "solid-js";
import type { Component, JSXElement, Accessor } from "solid-js";

interface NavButton {
  label: string;
  matchMode: string;
  onClick: () => void;
}

interface ModeSwitcherProps {
  buttons: NavButton[];
  mode: Accessor<string>;
  besideButtons?: JSXElement;
  containerClass?: string;
  indicatorClass?: string;
  buttonClass?: string;
}

export const ModeSwitcher: Component<ModeSwitcherProps> = (props) => {
  let containerRef: HTMLDivElement | undefined;
  let indicatorRef: HTMLDivElement | undefined;
  let animationTimeout: number | undefined;
  let cleanupTimeout: number | undefined;

  const [indicatorStyle, setIndicatorStyle] = createSignal({
    transform: "translateX(0)",
    width: "0px",
  });

  const [prevPosition, setPrevPosition] = createSignal({ left: 0, width: 0 });
  const [isAnimating, setIsAnimating] = createSignal(false);

  onCleanup(() => {
    if (animationTimeout) window.clearTimeout(animationTimeout);
    if (cleanupTimeout) window.clearTimeout(cleanupTimeout);
  });

  createEffect(() => {
    const currentMode = props.mode();

    if (isAnimating()) return;

    const buttons = containerRef?.querySelectorAll("button");
    if (!buttons) return;

    const activeButton = Array.from(buttons).find(
      (btn) => btn.dataset.mode === currentMode
    );

    if (activeButton && containerRef) {
      const containerLeft = containerRef.getBoundingClientRect().left;
      const buttonLeft = activeButton.getBoundingClientRect().left;
      const buttonWidth = activeButton.offsetWidth;

      const newLeft = buttonLeft - containerLeft;
      const oldLeft = prevPosition().left;

      // Skip animation if it's the initial position
      if (oldLeft === 0 && prevPosition().width === 0) {
        setIndicatorStyle({
          transform: `translateX(${newLeft}px)`,
          width: `${buttonWidth}px`,
        });
        setPrevPosition({ left: newLeft, width: buttonWidth });
        return;
      }

      setIsAnimating(true);

      // Stretch
      const leftPosition = Math.min(oldLeft, newLeft);
      const width = Math.abs(newLeft - oldLeft) + buttonWidth;

      setIndicatorStyle({
        transform: `translateX(${leftPosition}px)`,
        width: `${width}px`,
      });

      // Clean up any existing timeouts
      if (animationTimeout) window.clearTimeout(animationTimeout);
      if (cleanupTimeout) window.clearTimeout(cleanupTimeout);

      // Contract
      animationTimeout = window.setTimeout(() => {
        setIndicatorStyle({
          transform: `translateX(${newLeft}px)`,
          width: `${buttonWidth}px`,
        });
        setPrevPosition({ left: newLeft, width: buttonWidth });

        // Clear animation flag after animation completes
        cleanupTimeout = window.setTimeout(() => {
          setIsAnimating(false);
        }, 150);
      }, 150);
    }
  });

  return (
    <div
      ref={(ref) => (containerRef = ref)}
      class="relative border-b-2 border-gray-300"
      classList={{ [`${props.containerClass}`]: !!props.containerClass }}
    >
      {/* Buttons */}
      <div class="flex gap-4">
        <For each={props.buttons}>
          {(button) => (
            <button
              data-mode={button.matchMode}
              onClick={button.onClick}
              class={`py-3 font-medium transition-colors active:translate-y-0.5
                ${
                  props.mode() === button.matchMode
                    ? "text-roma-blue"
                    : "text-gray-600"
                }
                hover:text-roma-blue focus:outline-none`}
              classList={{ [`${props.buttonClass}`]: !!props.buttonClass }}
            >
              {button.label}
            </button>
          )}
        </For>
        <Show when={props.besideButtons}>{props.besideButtons}</Show>
      </div>

      {/* Indicator */}
      <div
        ref={(ref) => (indicatorRef = ref)}
        class="absolute bottom-[-2px] h-[2px] bg-roma-blue transition-all duration-300 ease-out rounded-full"
        style={indicatorStyle()}
        classList={{ [`${props.indicatorClass}`]: !!props.indicatorClass }}
      />
    </div>
  );
};
