import {
  Component,
  For,
  Suspense,
  Show,
  ErrorBoundary,
  createEffect,
} from "solid-js";
import { isServer } from "solid-js/web";
import {
  action,
  createAsync,
  reload,
  revalidate,
  useAction,
  useNavigate,
  useSubmission,
  A,
} from "@solidjs/router";
import { getProducts } from "~/services/products";
import {
  getFavourites,
  deleteFavouriteAction,
  importBulkFavouritesAction,
} from "~/services/favourites";
import { LineImage } from "~/components/ordering/checkout";
import { PT } from "~/utils/products";
import { LineItem } from "~/services/roma-api/cart/types-lineItems";
import {
  commaSeparatedSkus,
  getCookie,
  deleteCookie,
  getCookieFavourites,
} from "./fav-panel/utils";
import type { Product } from "~/services/roma-api/products/types";
import fractionString from "~/utils/fraction";
import { PanelItemMenu, type PanelItemMenuGroup } from "./PanelItemMenu";
import { Icon } from "solid-heroicons";
import {
  ellipsisVertical,
  trash,
  arrowTopRightOnSquare,
  shoppingCart,
  heart,
  plus,
} from "solid-heroicons/outline";
import { PanelLoadingBlocker } from "./PanelLoadingBlocker";
import {
  useErrorContext,
  useSessionContext,
  useSiteContext,
} from "~/utils/contexts";
import { PanelItemsSkeleton } from "./PanelItemsSkeleton";
import { checkError } from "~/services/roma-api/errors";
import { FavouriteTypeOption } from "~/services/roma-api/favourites/types";
import { usePrompt } from "~/components/utility";

export const FavouritesPanel = () => {
  const { permission, clearSession } = useSessionContext();
  const { setPanel } = useSiteContext();
  const { addError } = useErrorContext();
  const [Prompt, openPrompt] = usePrompt();
  const navigate = useNavigate();

  const hasFavouritesInCookie = () => {
    if (!isServer) {
      return Boolean(getCookie("romaprd.favourites"));
    }
    return false;
  };

  const favourites = createAsync(
    async () => {
      try {
        const favs = await getFavourites();

        if (favs) {
          const favList = Array.from(favs).map(([key, value]) => {
            const [sku, type] = key.split("-");
            return { sku, type, value };
          });

          // get the product data from api
          // TODO - Error Handling / Sentry
          // currently if getProducts fails, its being handled as a favourites error..
          // display a separate/secondary notification for this failure?
          const productData = await getProducts({
            skus: commaSeparatedSkus(favList),
            fields: [
              "SKU",
              "Collection",
              "ColourDescription",
              "Category",
              "Width",
              "Height",
              "Rabbet",
              "Face",
              "Depth",
            ],
          });
          // Create a map from the product data for faster access
          const productMap = productData.Results.reduce((acc, product) => {
            acc[product.SKU] = product;
            return acc;
          }, {} as Record<string, Product>);

          // merge
          return favList.map((favItem) => ({
            ...favItem,
            productData: productMap[favItem.sku] || null,
          }));
        }
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[FavouritesPanel.tsx]: Caught error: ", err);
        }

        addError(err, {
          severity: "warning",
          title: "Favorites Error",
          message:
            "An error occurred while retrieving favorites - adding and deleting favorites may not function normally during your session.",
          suggestions: [
            "Please try signing out and signing back in.",
            "If this error persists, please contact support.",
          ],
          actions: [
            {
              label: "Sign Out",
              action: async () => {
                setPanel({ mode: "fav", open: false });
                await clearSession();
              },
              dismissAfter: true,
            },
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
          showDetails: true,
        });
        throw err;
      }
    },
    // @ts-expect-error
    { initialValue: [], ssrLoadFrom: "initial" }
  );

  const cookieFaves = createAsync(
    async () => {
      if (hasFavouritesInCookie()) {
        const { map, formatted } = getCookieFavourites();

        const favs = map;

        if (favs) {
          const favList = Array.from(favs).map(([key, value]) => {
            const [sku, type] = key.split("-");
            return { sku, type, value };
          }) as { sku: string; type: PT; value: { ID: string | null } }[];

          // get the product data from api
          // TODO - Error Handling / Sentry
          // currently if getProducts fails, its being handled as a favourites error..
          // display a separate/secondary notification for this failure?
          const productData = await getProducts({
            skus: commaSeparatedSkus(favList),
            fields: [
              "SKU",
              "Collection",
              "ColourDescription",
              "Category",
              "Width",
              "Height",
              "Rabbet",
              "Face",
              "Depth",
            ],
          });
          // Create a map from the product data for faster access
          const productMap = productData.Results.reduce((acc, product) => {
            acc[product.SKU] = product;
            return acc;
          }, {} as Record<string, Product>);

          // merge
          return favList.map((favItem) => ({
            ...favItem,
            productData: productMap[favItem.sku] || null,
          }));
        }
      } else {
        return [];
      }
    },
    // @ts-ignore
    { ssrLoadFrom: "initial", initialValue: [] }
  );

  // * MIGRATION * //
  const clearCookieAndRevalidateAction = action(async () => {
    deleteCookie("romaprd.favourites");
    await revalidate(getFavourites.key);
  });

  const migrateAll = useAction(importBulkFavouritesAction);
  const migrating = useSubmission(importBulkFavouritesAction);
  const dismissAndDelete = useAction(clearCookieAndRevalidateAction);

  const handleMigration = async () => {
    try {
      let payload: { SKU: string; Type: FavouriteTypeOption }[] = [];

      if (cookieFaves()) {
        cookieFaves()?.forEach((item) => {
          payload.push({
            SKU: item.sku,
            Type: item.type as FavouriteTypeOption,
          });
        });
      }

      // console.log("PAYLOAD! >>> ", payload);

      const response = await migrateAll(payload);
      if (response) {
        await dismissAndDelete();
      }
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          "[CookieFavouritesMigrator.tsx]: error caught in handleMigration fn: ",
          err
        );
      }
      addError(err, {
        severity: "critical",
        title: "Error migrating cookie favorites",
        autoDisappear: true,
      });
    }
  };

  const handleDismissAndDelete = async () => {
    try {
      const confirmed = await openPrompt({
        title: "Confirm Deletion",
        description:
          "Are you sure you want to clear these favorites? This action is irreversible.",
        requireInteraction: true,
        options: [
          { label: "Cancel", value: false, priority: true },
          { label: "Clear Old Favorites", value: true, style: "red" },
        ],
      });

      if (confirmed) {
        await dismissAndDelete();
      }
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          "[CookieFavouritesMigrator.tsx]: Error clearing favourites from cookies"
        );
      }

      addError(err, {
        severity: "critical",
        title: "Error clearing cookie favorites",
        autoDisappear: true,
      });
    }
  };

  // * END MIGRATION FNs * //

  const deleteFav = useAction(deleteFavouriteAction);
  const deletingFav = useSubmission(deleteFavouriteAction);

  const handleRemoveFav = async (id: string) => {
    return await deleteFav(id);
  };

  // TODO: Eventually extract this into a reusable comp that can be used in Search, anywhere products are shown in list form
  const ProductItemAttributes: Component<{ product: Product; type: PT }> = (
    props
  ) => {
    return (
      <div class="flex flex-col">
        <p class="font-bold text-sm sm:text-base">{props.product.SKU}</p>
        <p class="text-xs sm:text-sm">
          {props.product.Collection}, {props.product.ColourDescription}
        </p>
        <Show when={![PT.GALLERYFRAME, PT.PHOTOFRAME].includes(props.type)}>
          <p class="text-xs  fraction flex gap-2">
            <Show when={props.product.Width}>
              <span class="whitespace-nowrap">
                W{fractionString(props.product.Width)}
              </span>
            </Show>
            <Show when={props.product.Height}>
              <span class="whitespace-nowrap">
                H{fractionString(props.product.Height)}
              </span>
            </Show>
            <Show when={props.product.Rabbet && props.product.Rabbet > 0}>
              <span class="whitespace-nowrap">
                R{fractionString(props.product.Rabbet)}
              </span>
            </Show>
            <Show when={props.product.Depth}>
              <span class="whitespace-nowrap">
                D{fractionString(props.product.Depth)}
              </span>
            </Show>
            <Show when={props.product.Face}>
              <span class="whitespace-nowrap">
                F{fractionString(props.product.Face)}
              </span>
            </Show>
          </p>
        </Show>
      </div>
    );
  };

  type FavPanelItemProps = {
    fav: {
      sku: string;
      type: PT;
      value: { ID: string | null };
      productData: Product;
    };
    hasNotBeenMigrated?: boolean;
  };

  const FavPanelItem: Component<FavPanelItemProps> = (props) => {
    const favToLine = (fav: typeof props.fav) => {
      return {
        Type: fav.type,
        ID: fav.value.ID,
        SubItems: [
          {
            Moulding: fav.sku,
          },
        ],
      };
    };
    const productURLAppend = (type: PT) => {
      switch (type) {
        case PT.PHOTOFRAME:
          return "/photo-frame";
        case PT.GALLERYFRAME:
          return "/gallery-frame";
        case PT.MIRROR:
          return "/mirror";
        default:
          return "";
      }
    };

    // ? Not great - forcing the fav as a LineItem to work with LineImage..
    // ? Eventually refactor/remake a new Image comp that can serve both lines and other types
    const line = favToLine(props.fav) as LineItem;

    const orderUrl = `/order?sku=${props.fav.sku}${
      [PT.GALLERYFRAME, PT.PHOTOFRAME].includes(props.fav.type)
        ? `&type=${props.fav.type}`
        : ""
    }`;

    const list: PanelItemMenuGroup = {
      list: [
        {
          label: "View product",
          icon: arrowTopRightOnSquare,
          href: `/product/${props.fav.sku}${productURLAppend(props.fav.type)}`,
        },
        {
          label: "Order",
          icon: shoppingCart,
          href: orderUrl,
          disabled: !permission.PLACEORDER || !permission.ORDERPRODUCT,
        },
        {
          label: "Remove from favourites",
          icon: trash,
          disabled: !props.fav.value.ID,
          onSelect: async () => {
            if (props.fav.value.ID) {
              await handleRemoveFav(props.fav.value.ID);
            }
          },
        },
      ],
    };

    return (
      <li class="flex py-1.5 group/fav">
        <div class="shrink-0">
          <LineImage line={line} size="sm" />
        </div>
        <div
          class={`ml-1.5 px-1 grow ${
            props.hasNotBeenMigrated
              ? "bg-faint-blue"
              : "group-hover/fav:bg-roma-grey "
          } `}
        >
          <ProductItemAttributes
            product={props.fav.productData}
            type={props.fav.type}
          />
        </div>
        <Show when={!!props.fav.value.ID}>
          <PanelItemMenu
            placement="left"
            gutter={0}
            list={list}
            triggerClass="text-roma-medium-grey hover:text-black transition-colors"
          >
            <Icon path={ellipsisVertical} class="w-5 h-5" />
          </PanelItemMenu>
        </Show>
      </li>
    );
  };

  return (
    <div class="grow overflow-y-auto">
      <ul
        class="divide-y overflow-y-auto flex flex-col text-roma-dark-grey h-full"
        classList={{ "pointer-events-none": deletingFav.pending }}
      >
        <Show when={deletingFav.pending}>
          <PanelLoadingBlocker />
        </Show>

        <Suspense fallback={<PanelItemsSkeleton />}>
          {/* TODO CustomErrorBoundary -> 'retry' button? */}
          <ErrorBoundary
            fallback={
              <div class="h-full flex items-center justify-center">
                Error retrieving account favourites
              </div>
            }
          >
            <Show when={hasFavouritesInCookie() && cookieFaves()}>
              <Show when={!favourites() || favourites()?.length === 0}>
                <div class="bg-roma-blue text-white rounded-md my-2 text-sm p-3 flex flex-col">
                  <div class="font-medium border-b border-white mb-2 flex item-center pb-3">
                    <Icon
                      path={heart}
                      class="w-5 h-5 mr-1 animate-pulse"
                      stroke-width={2.5}
                    />
                    <h3>Favourites Update</h3>
                    <p class="font-light italic ml-auto text-white/70">
                      Action Required
                    </p>
                  </div>
                  <p>
                    Your favorites will soon be securely saved to your account,
                    making them accessible on any device. Ready to migrate your
                    favorites? Click below to learn more and get started!
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/account/favorites");
                      setPanel({ mode: "fav", open: false });
                    }}
                    class="px-3 py-1 bg-white text-roma-blue rounded-md self-end mt-3 active:translate-y-0.5"
                  >
                    Learn More & Migrate
                  </button>
                </div>
              </Show>
              <Show when={favourites() && favourites()!.length > 0}>
                <div class="bg-roma-blue text-white rounded-md my-2 text-sm p-3 flex flex-col gap-2">
                  <div class="font-medium border-b border-white mb-2 pb-3 flex item-center ">
                    <Icon
                      path={heart}
                      class="w-5 h-5 mr-1 animate-pulse"
                      stroke-width={2.5}
                    />
                    <h3> We've found more favorites!</h3>
                  </div>
                  <p class="text-xs">
                    You may be seeing this message again because you had
                    favorites saved on multiple devices.
                  </p>
                  <p class="text-xs">
                    To dismiss this message on this device, add or clear them
                    below.
                  </p>
                </div>
              </Show>
            </Show>

            <Show when={hasFavouritesInCookie() && cookieFaves()}>
              <div class="m-1">
                <div class="m-2 divide-y  p-4 border-2 border-roma-blue rounded-lg">
                  <div class="pb-1 flex justify-between items-center">
                    <p class="font-medium">Found Favorites</p>
                    {/* TODO -  */}
                    <button
                      onClick={handleMigration}
                      type="button"
                      class="bg-roma-blue px-3 py-1 text-sm text-white rounded-lg active:translate-y-0.5 flex items-center"
                    >
                      <span>Add to Account Favs</span>
                      <Icon
                        path={plus}
                        stroke-width={2}
                        class="w-4 h-4 ml-1.5"
                      />
                    </button>
                  </div>
                  <For each={cookieFaves()}>
                    {(fav) => <FavPanelItem fav={fav} hasNotBeenMigrated />}
                  </For>
                  <div class="flex">
                    <button
                      type="button"
                      onClick={handleDismissAndDelete}
                      class="text-red-600 text-xs ml-auto mt-1 active:translate-y-0.5"
                    >
                      Dismiss / Clear
                    </button>
                  </div>
                </div>
              </div>
            </Show>

            <Show
              when={favourites() && favourites()!.length > 0}
              fallback={
                <div class="flex flex-col items-center justify-center h-full">
                  <div>
                    <p class="text-lg">No favorites found!</p>
                    <ul class="text-sm mt-4 list-disc list-inside">
                      <li>
                        Browse mouldings in the{" "}
                        <A href="/shop" class="text-roma-blue">
                          Shop
                        </A>
                      </li>
                      <li>
                        Check out our{" "}
                        <A
                          href="/shop?collection=Gallery Frames"
                          class="text-roma-blue"
                        >
                          Gallery Frames
                        </A>
                      </li>
                      <li>
                        Explore our{" "}
                        <A href="/new" class="text-roma-blue">
                          Latest Collection!
                        </A>
                      </li>
                    </ul>
                  </div>
                </div>
              }
            >
              <For each={favourites()}>
                {(fav) => <FavPanelItem fav={fav} />}
              </For>
            </Show>
          </ErrorBoundary>
        </Suspense>
      </ul>
      {Prompt}
    </div>
  );
};
