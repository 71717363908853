import { Component, Show, For, createSignal } from "solid-js";
import { Motion } from "solid-motionone";
import { type ErrorNotification, useErrorContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import {
  exclamationCircle,
  exclamationTriangle,
  informationCircle,
  chevronDown,
  chevronUp,
  chevronDoubleDown,
  chevronDoubleUp,
  xMark,
  shieldCheck,
  square_2Stack,
  checkCircle,
  flag,
} from "solid-heroicons/outline";
import { DismissButton } from "./DismissButton";
import { TextFieldInput } from "~/components/inputs";

import * as Sentry from "@sentry/browser";

type NotificationItemProps = {
  notification: ErrorNotification;
  onDismiss: (id: string) => void;
  onFeedbackSubmit: (id: string, feedback: string) => Promise<void>;
};

const ErrorNotificationItem: Component<NotificationItemProps> = (props) => {
  const { reportErrorToSentry, updateNotificationSentryId } = useErrorContext();
  const [isExpanded, setIsExpanded] = createSignal(false);
  const [isHovered, setIsHovered] = createSignal(false);
  const [showCopied, setShowCopied] = createSignal(false);
  const [showFeedback, setShowFeedback] = createSignal(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = createSignal(false);
  const isPaused = () => isExpanded() || isHovered();
  let feedbackRef: HTMLFormElement | undefined;

  const handleReportError = () => {
    if (props.notification.originalError) {
      const eventId = reportErrorToSentry(props.notification.originalError);
      if (eventId) {
        updateNotificationSentryId(props.notification.id, eventId);
      }
    }
  };

  const handleSubmitFeedback = () => {
    if (!feedbackRef) return;

    const formData = new FormData(feedbackRef);
    const messageValue = formData.get("message") as string;

    if (messageValue && props.notification.sentryEventId) {
      Sentry.captureFeedback(
        {
          associatedEventId: props.notification.sentryEventId,
          message: messageValue,
          name: (formData.get("name") as string) || undefined,
          email: (formData.get("email") as string) || undefined,
        },
        { includeReplay: true }
      );

      // TODO Sentry
      // Check if email/name can be added to Sentry context for subsequent feedback submissions and autopopulate the fields
      // TODO add some validation on the basic submission form, working for now.

      feedbackRef.reset();
      setFeedbackSubmitted(true);
    }
  };

  const handleCopyId = () => {
    if (!props.notification.sentryEventId) return;
    navigator.clipboard
      .writeText(props.notification.sentryEventId)
      .then(() => {
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  return (
    <div
      class={`relative scrollbar-none ${
        props.notification.severity === "critical"
          ? isExpanded()
            ? "bg-red-700"
            : "bg-red-600"
          : isExpanded()
          ? "bg-orange-700"
          : "bg-orange-600"
      } border-y border-white/50`}
      // Motion div was breaking when I added the conditional bg-colors...
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Collapsed View */}
      <div
        class={` h-[3.25rem] ${
          props.notification.severity === "critical"
            ? isExpanded()
              ? "bg-red-700"
              : "bg-red-600"
            : isExpanded()
            ? "bg-orange-700"
            : "bg-orange-600"
        }
          flex items-center justify-between  cursor-pointer
          container mx-auto
        `}
        onClick={() =>
          props.notification?.showDetails && setIsExpanded((prev) => !prev)
        }
      >
        <div class="flex items-center space-x-3">
          <div class="flex-shrink-0">
            <Icon
              path={
                props.notification.severity === "critical"
                  ? exclamationTriangle
                  : informationCircle
              }
              class="w-5 h-5"
            />
          </div>
          <div>
            <h3 class="font-medium">
              {props.notification.title}{" "}
              {/* <Show when={props.notification.occurrences > 1}>
                <span class="text-sm opacity-75">
                  ({props.notification.occurrences}x occurrences)
                </span>
              </Show> */}
            </h3>
            <Show when={!props.notification.showDetails}>
              <p>{props.notification.message}</p>
            </Show>
          </div>
        </div>

        <div class="flex items-center space-x-2">
          <Show when={props.notification.showDetails}>
            <button
              class="p-1 hover:bg-white/10 rounded active:translate-y-0.5"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded((prev) => !prev);
              }}
            >
              <Icon
                path={isExpanded() ? chevronUp : chevronDown}
                class="w-5 h-5"
              />
            </button>
          </Show>

          <Show when={props.notification.dismissible}>
            <DismissButton
              notification={props.notification}
              onDismiss={() => props.onDismiss(props.notification.id)}
              isPaused={isPaused()}
            />
          </Show>
        </div>
      </div>

      {/* Expanded View */}
      <Show when={isExpanded()}>
        <Motion.div
          class={`p-4 ${
            props.notification.severity === "critical"
              ? "bg-red-600"
              : "bg-orange-600"
          } w-full`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div class="container">
            <p class="mb-2">{props.notification.message}</p>
            <Show when={props.notification.suggestions?.length}>
              <ul class="mb-2 text-sm list-inside list-disc">
                <Show when={props.notification.suggestionsLabel}>
                  <p class="font-medium pb-1">
                    {props.notification.suggestionsLabel}
                  </p>
                </Show>
                <For each={props.notification.suggestions}>
                  {(suggestion) => <li>{suggestion}</li>}
                </For>
              </ul>
            </Show>
            <Show when={props.notification.actions?.length}>
              <div class="flex space-x-2  pt-2 mb-2">
                <For each={props.notification.actions}>
                  {(action) => (
                    <button
                      onClick={async (e) => {
                        e.stopPropagation(); // Prevent expanding/collapsing when clicking action
                        await action.action();
                        if (action.dismissAfter) {
                          props.onDismiss(props.notification.id);
                        }
                      }}
                      class={`px-3 py-1 flex items-center justify-center active:translate-y-0.5 rounded ${
                        action.variant === "primary"
                          ? "bg-white text-red-600"
                          : "border border-white hover:bg-white/20"
                      }`}
                    >
                      <span class="mb-1">{action.label}</span>
                    </button>
                  )}
                </For>
              </div>
            </Show>

            <div class="border-l-2 border-white flex flex-col gap-1 pl-2 text-xs mt-4 ">
              <Show
                when={props.notification.sentryEventId}
                // when={false}
                fallback={
                  <>
                    <div class="flex items-center">
                      <Icon path={flag} class="w-3 h-3 mr-1" />
                      <p>This error has not been reported.</p>
                    </div>
                    <button
                      type="button"
                      onClick={handleReportError}
                      class="text-xs px-2 py-0.5 bg-white text-roma-dark-grey self-start rounded-[4px] flex items-center active:translate-y-0.5"
                    >
                      {/* <Icon path={flag} class="w-3 h-3 mr-1"/> */}
                      <span>Report</span>
                    </button>
                  </>
                }
              >
                <div class="flex items-center">
                  <Icon path={shieldCheck} class="w-5 h-5 mr-1.5" />
                  <p>This error has been reported.</p>
                </div>
                <div class="relative bg-white/80 rounded-[4px] text-roma-medium-grey font-light self-start px-2 py-0.5 flex items-center">
                  <span class="select-all font-mono">
                    ID:{props.notification.sentryEventId}
                  </span>
                  <button type="button" onClick={handleCopyId}>
                    <Icon path={square_2Stack} class="ml-1 w-3 h-3" />
                  </button>
                  {/* TODO - Add Motion fade-in/out */}
                  <Show when={showCopied()}>
                    <div class="absolute -right-2 translate-x-full bg-roma-grey rounded-[4px] text-roma-dark-grey px-1 py-0.5 text-xs">
                      Copied!
                    </div>
                  </Show>
                </div>
                <Show
                  // HERE
                  // when={props.notification.allowFeedback}
                  when={true}
                >
                  <button
                    onClick={() => setShowFeedback((prev) => !prev)}
                    type="button"
                    class="self-start flex items-center"
                  >
                    <span>Submit Feedback</span>
                    <Icon
                      path={showFeedback() ? chevronUp : chevronDown}
                      class="w-3 h-3 ml-1"
                      stroke-width={2}
                    />
                  </button>
                  <Show when={showFeedback()}>
                    <div class="md:w-1/2 class flex flex-col gap-2 mt-3">
                      <Show
                        when={feedbackSubmitted()}
                        fallback={
                          <form
                            ref={(ref) => (feedbackRef = ref)}
                            action=""
                            class="flex flex-col gap-2"
                          >
                            <p>
                              Your insights help us understand what went wrong
                              and improve your experience. Please share any
                              details about what happened when this error
                              occurred, and our team will work to resolve it as
                              quickly as possible. Thank you for helping us make
                              Roma better!
                            </p>
                            <div class="flex flex-col md:flex-row gap-2 md:items-center">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                class="w-full px-2 py-1 bg-white/90 rounded-[4px] border border-gray-200 text-sm text-roma-dark-grey placeholder:text-xs"
                                placeholder="Name (Optional)"
                              />
                              <input
                                type="text"
                                id="email"
                                name="email"
                                class="w-full px-2 py-1 bg-white/90 rounded-[4px] border border-gray-200 text-sm text-roma-dark-grey placeholder:text-xs"
                                placeholder="Email (Optional)"
                              />
                            </div>
                            <textarea
                              id="message"
                              name="message"
                              class="w-full p-2 rounded bg-white/90 text-roma-dark-grey border border-gray-200 text-sm placeholder:text-xs "
                              placeholder="What were you trying to do when this error occurred?"
                              rows={4}
                            />
                            <button
                              onClick={handleSubmitFeedback}
                              type="button"
                              class="bg-white px-2 py-1  text-sm rounded-[4px] text-roma-dark-grey active:translate-y-0.5"
                            >
                              Submit Feedback
                            </button>
                          </form>
                        }
                      >
                        <div class="flex items-center">
                          <Icon path={checkCircle} class="w-5 h-5 mr-1" />
                          <span class="font-medium">
                            Thank you for your feedback!{" "}
                          </span>
                        </div>
                        <p>
                          We’ve received your report and are looking into the
                          issue. Your input helps us improve and provide a
                          better experience. We appreciate your patience and
                          support!
                        </p>
                        <p>
                          To reference this issue, please copy the above error
                          ID, or screenshot this notification.
                        </p>
                      </Show>
                    </div>
                  </Show>
                </Show>
              </Show>
            </div>
          </div>
        </Motion.div>
      </Show>
    </div>
  );
};

export const ErrorNotificationsContainer: Component = () => {
  const {
    notifications,
    removeError,
    submitFeedback,
    clearErrors,
    reportErrorToSentry,
  } = useErrorContext();
  const [isListExpanded, setIsListExpanded] = createSignal(false);

  return (
    <Show
      when={notifications.length > 1}
      fallback={
        <Show when={notifications.length === 1}>
          <ErrorNotificationItem
            notification={notifications[0]}
            onDismiss={removeError}
            onFeedbackSubmit={submitFeedback}
          />
        </Show>
      }
    >
      <Motion.div
        class="bg-red-600 "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div
          class="h-[3.25rem] container mx-auto flex items-center justify-between cursor-pointer"
          onClick={() => setIsListExpanded((prev) => !prev)}
        >
          <div class="flex items-center space-x-3">
            <Icon path={exclamationCircle} class="w-5 h-5" />
            <span>{notifications.length} Errors</span>
          </div>
          <div class="flex items-center space-x-2">
            <button
              class="p-1 hover:bg-white/10 rounded active:translate-y-0.5"
              onClick={(e) => {
                e.stopPropagation();
                setIsListExpanded((prev) => !prev);
              }}
            >
              <Icon
                path={isListExpanded() ? chevronDoubleUp : chevronDoubleDown}
                class="w-5 h-5"
              />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                clearErrors();
              }}
              class="flex items-center group active:translate-y-0.5 p-1"
            >
              <span class="border-b border-transparent group-hover:border-white">
                Dismiss All
              </span>
              <Icon path={xMark} class=" ml-1 w-5 h-5" />
            </button>
          </div>
        </div>
        <Show when={isListExpanded()}>
          <Motion.div
            class="max-h-[70vh] overflow-y-auto"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
          >
            <For each={notifications}>
              {(notification) => (
                <ErrorNotificationItem
                  notification={notification}
                  onDismiss={removeError}
                  onFeedbackSubmit={submitFeedback}
                />
              )}
            </For>
          </Motion.div>
        </Show>
      </Motion.div>
    </Show>
  );
};
