import {
  Accessor,
  Component,
  createEffect,
  createMemo,
  createSignal,
  Index,
  Match,
  onCleanup,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { isServer } from "solid-js/web";
import { A, useNavigate, useSearchParams } from "@solidjs/router";

import { debounce } from "@solid-primitives/scheduled";
import { Motion, Presence } from "solid-motionone";
import { Icon } from "solid-heroicons";
import {
  chevronLeft,
  chevronRight,
  lifebuoy,
  magnifyingGlass,
  mapPin,
  plus,
  user,
} from "solid-heroicons/outline";

import { useErrorContext, useSessionContext, useSiteContext } from "~/utils/contexts";

import ContactInfo from "./ContactInfo";
import { ErrorNotificationsContainer } from "../utility/errors/ErrorNotifications";
import Hamburger from "./Hamburger";
import { HeaderFields } from "./Header.block";
import { HeaderSearch } from "../search";
import { Img } from "../image";
import { ProfileQuickLinks } from "../account";
import { SidePanel } from "./side-panel";
import { SignInModal } from "./SignInModal";
import { Colour } from "../product/product-page";

const NavSection: Component<{
  categories: NonNullable<HeaderFields["navigation"]>[number]["categories"];
  removeNav: VoidFunction;
  closeNav: VoidFunction;
  clearClose: VoidFunction;
  active: Accessor<boolean>;
  setHeight: (height: number) => void;
}> = (props) => {
  let navRef: HTMLDivElement;
  createEffect(() => {
    if (props.active()) props.setHeight(navRef.clientHeight + 20);
  });
  return (
    <div class="max-w-6xl w-full relative gap-2 justify-items-center container mx-auto px-2 print:hidden">
      <div
        ref={navRef!}
        onMouseEnter={() => props.clearClose()}
        onMouseLeave={() => props.closeNav()}
        classList={{
          "translate-x-0": props.active(),
          hidden: !props.active(),
        }}
        style={{ animation: "fadeIn 500ms" }}
        class="absolute grid my-5 w-full grid-cols-10 pt-6 pb-10 place-content-center transition-all duration-200 divide-x divide-roma-dark-grey/10"
      >
        <Index each={props.categories}>
          {(section, i) => (
            <div
              class={`col-span-2 w-full font-medium ${
                i == 0 ? " col-start-" + (6 - props.categories!.length) : ""
              }`}
            >
              <div class="pb-3 px-6 text-roma-dark-grey font-medium">
                {section().label}
              </div>
              <ul class="inline-flex flex-col w-full">
                <Show when={!!section().contentType}>
                  <Switch>
                    <Match when={section().contentType === "Contact Block"}>
                      <ContactInfo />
                    </Match>
                  </Switch>
                </Show>
                <Index each={section().links}>
                  {(link) => (
                    <li class="w-full group">
                      <A
                        class="inline-block w-full px-3"
                        classList={{
                          "text-roma-blue": link().highlight,
                        }}
                        href={link().url as string}
                        title={`Link to ${link().label}`}
                        target={link().newTab ? "_blank" : ""}
                        onClick={props.removeNav}
                      >
                        <div
                          class="rounded-lg px-3 py-2 group-hover:text-roma-blue transition-[color] duration-100 flex justify-between items-center"
                          classList={{
                            "group-hover:text-roma-dark-grey": link().highlight,
                          }}
                        >
                          {link().label}

                          <Show when={link().icon}>
                            <Img
                              src={link().icon!}
                              width={24}
                              height={24}
                              layout="constrained"
                              alt="Icon"
                            />
                          </Show>
                          <Show when={link().isNew}>
                            <div class="bg-roma-blue rounded-full px-2 flex items-center">
                              <p class="text-xs text-white">New</p>
                            </div>
                          </Show>
                          <Show when={link().hasColour}>
                            <Colour
                              colour={link().colour}
                              metallic={link().metallic}
                            />
                          </Show>
                        </div>
                      </A>
                    </li>
                  )}
                </Index>
              </ul>
            </div>
          )}
        </Index>
      </div>
    </div>
  );
};

const MobileNav: Component<{
  closeNav: VoidFunction;
  openNav: (index: number) => void;
  currentNav: () => number | null;
  setCurrentNav: (val: null | number) => void;
  override: () => boolean;
  setOverride: (expanded: boolean) => void;
  structure: HeaderFields["navigation"];
}> = (props) => {
  const { isPartner, permission } = useSessionContext();
  const setOverflow = (value: string) =>
    (window.document.body.style.overflow = value);
  if (!isServer) {
    createEffect(() =>
      setOverflow(props.override() === true ? "hidden" : "auto")
    );
    onCleanup(() => setOverflow("auto"));
  }
  const back = () => props.setCurrentNav(null);
  const open = (index: number) => {
    console.log(props.currentNav(), index);
    if (props.currentNav() == index) {
      props.setCurrentNav(null);
    } else {
      props.openNav(index);
    }
  };
  const navigate = useNavigate();
  onMount(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    onCleanup(() => {
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    });
  });

  return (
    <Motion.div
      class="flex flex-col bg-white h-screen scroll-auto overflow-auto w-screen fixed left-0 top-0 z-40"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.05 } }}
      transition={{ duration: 0.05, easing: "ease-in-out" }}
    >
      <button
        aria-label="Back"
        onTouchStart={back}
        class="flex lg:hidden gap-2 items-center px-10 py-12 transition-opacity duration-50"
        disabled={props.currentNav() === null}
        classList={{
          "opacity-0": props.currentNav() === null,
        }}
      >
        <Icon class="w-4" path={chevronLeft} stroke="black" />
        Back
      </button>
      <ul class="flex flex-col items-center w-full text-md lex-col divide-y divide-roma-grey px-7">
        <Index each={props.structure}>
          {(section, index) => (
            <Show
              when={props.currentNav() == null || props.currentNav() == index}
            >
              <li class="flex w-full flex-col lg:w-auto md:items-center cursor-pointer lg:transition lg:duration-300">
                <button
                  aria-label={section().label}
                  onTouchStart={(e) => {
                    e.preventDefault();
                    open(index);
                  }}
                  class="w-full flex justify-between px-4 py-4"
                >
                  <div>{section().label}</div>
                  <Show when={props.currentNav() == null}>
                    <Icon class="w-3" path={chevronRight} />
                  </Show>
                </button>
                <Show when={props.currentNav() == index}>
                  <ul class="mt-4 text-gray-500 text-[0.85rem] w-full lg:hidden">
                    <Index each={section().categories}>
                      {(category) => (
                        <li class="border-b">
                          <input
                            type="checkbox"
                            name="submenu"
                            id={category().label}
                            class="accordion-input peer sr-only"
                          />
                          <label
                            for={category().label}
                            class="accordion-label py-4 px-4 flex justify-between items-center peer-checked:ml-0 peer-checked:mb-0 transition-all"
                          >
                            {category().label}
                            <Icon
                              class="w-4 parent:peer-checked:rotate-45"
                              path={plus}
                            />
                          </label>
                          <ul class="flex flex-col text-xs font-semibold max-h-0 peer-checked:mb-7 peer-checked:max-h-80 peer-checked:bg-green500 sm:max-h-max overflow-hidden transition-all duration-700">
                            <Index each={category().links}>
                              {(link) => (
                                <li>
                                  <A
                                    class="block pl-9 py-3"
                                    onTouchStart={() => {
                                      navigate(link().url!);
                                      props.closeNav();
                                    }}
                                    href={link().url!}
                                  >
                                    {link().label}
                                  </A>
                                </li>
                              )}
                            </Index>
                          </ul>
                        </li>
                      )}
                    </Index>
                  </ul>
                </Show>
              </li>
            </Show>
          )}
        </Index>
        <Show when={isPartner() && permission.PLACEORDER}>
          <li class="flex w-full flex-col lg:w-auto md:items-center cursor-pointer lg:transition lg:duration-300">
            <A
              class="w-full flex justify-between px-4 py-4"
              onTouchStart={props.closeNav}
              href="/order"
            >
              <div>Order</div>
              <Show when={props.currentNav() == null}>
                <Icon class="w-3" path={chevronRight} />
              </Show>
            </A>
          </li>
        </Show>
      </ul>
      <Show when={props.currentNav() === null}>
        <Help class="flex-col border-0 w-auto fixed justify-start space-x-0 space-y-6 bottom-10 pl-10" />
      </Show>
    </Motion.div>
  );
};

export const Help: Component<{ class?: string }> = (props) => {
  const { isPartner } = useSessionContext();
  const [params, setParams] = useSearchParams<{
    signIn: string;
  }>();

  return (
    <nav
      class="flex text-xs justify-end mx-auto  child:px-3 md:-mr-3 print:hidden"
      classList={{ [props.class!]: !!props.class }}
    >
      <SignInModal
        open={() => !!params.signIn}
        onClose={() => setParams({ signIn: undefined })}
      />
      <div class="flex items-center md:divide-x md:divide-roma-dark-grey child:px-3 md:-mr-3">
        <A
          class="group hover:text-roma-blue flex my-1 items-center space-x-2"
          href="/support/authorized-retailers"
        >
          <Icon
            class="w-5 group:hover:fill-roma-blue"
            stroke-width="1.75"
            path={mapPin}
          />
          <span>Find a Retailer</span>
        </A>
        <A
          class="group hover:text-roma-blue flex my-1 items-center space-x-2"
          href="/support"
        >
          <Icon
            class="w-5 group:hover:fill-roma-blue"
            stroke-width="1.75"
            path={lifebuoy}
          />
          <span>Help</span>
        </A>
        <Show
          when={isPartner()}
          fallback={
            <button
              aria-label="Sign in"
              class="group hover:text-roma-blue flex my-1 items-center space-x-1"
              onClick={() => {
                setParams({ signIn: true });
              }}
            >
              <Icon
                class="w-5 group:hover:fill-roma-blue"
                stroke-width="1.75"
                path={user}
              />
              <div>Sign In</div>
            </button>
          }
        >
          <ProfileQuickLinks />
        </Show>
      </div>
    </nav>
  );
};

const Nav: Component<{
  show: Accessor<boolean>;
  structure: HeaderFields["navigation"];
}> = (props) => {
  const { isPartner, permission } = useSessionContext();
  const {
    breakpoints,
    headerVisible: [headerVisible, top],
  } = useSiteContext();
  const { notifications } = useErrorContext();
  const [params, setParams] = useSearchParams();
  const [currentNav, setCurrentNav] = createSignal<null | number>(null);
  const [height, setHeight] = createSignal(0);
  const [override, setOverride] = createSignal(false);
  const removeNav = () => {
    setCurrentNav(null);
    setOverride(false);
  };
  const closeNav = debounce(removeNav, 150);
  const openNav = (index: number) => {
    setCurrentNav(index);
    closeNav.clear();
  };
  createEffect(() => {
    if (!headerVisible() && currentNav() !== null) {
      setCurrentNav(null);
    }
  });

  const nav = createMemo(() => {
    if (!props.structure) return;
    let options = [...props.structure!];
    if (isPartner() === true && permission.PLACEORDER) {
      options.push({ url: "/order", label: "Order", categories: [] });
    }
    return options;
  });
  return (
    <>
      {/* <Presence exitBeforeEnter> */}
      <Show when={currentNav() !== null || !!params.search}>
        <Motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.25 } }}
          transition={{ duration: 0.5 }}
          class="fixed w-screen h-screen top-0 left-0 backdrop-blur-sm bg-black/25 z-0"
          onMouseEnter={closeNav}
          onClick={() => {
            if (params.search != "") {
              setParams({ search: undefined });
            }
          }}
        />
      </Show>
      {/* </Presence> */}
      <header
        class="w-full transition-shadow duration-300 z-10 print:hidden relative"
        classList={{ "shadow-lg": !top() }}
      >
        {/* TEST */}
        {/* <div class="bg-red-200 border-2 border-red-500 absolute bottom-0 translate-y-full inset-x-0 z-[999]">
          TEST
        </div> */}
        <div class="backdrop-blur-xl bg-white/98">
          <div class="bg-roma-grey ">
            <div class="mx-auto container">
              <Help class="py-1" />
            </div>
          </div>
          {/* <Presence exitBeforeEnter> */}
          <Show when={!isServer && !breakpoints.lg && override()}>
            <MobileNav
              closeNav={closeNav}
              openNav={openNav}
              currentNav={currentNav}
              override={override}
              setCurrentNav={setCurrentNav}
              setOverride={setOverride}
              structure={props.structure}
            />
          </Show>
          {/* </Presence> */}
          <nav class="grid grid-cols-12 gap-5  mx-auto container items-center">
            <A class="flex col-span-6 lg:col-span-3 z-30 " href="/">
              <img
                src="/img/logo-tm.svg"
                width={225}
                height={44}
                alt="RomaMoulding.com"
              />
            </A>
            <div
              class="hidden lg:flex lg:col-span-6"
              classList={{
                "flex! bg-white h-screen scroll-auto overflow-auto w-screen fixed left-0 top-0 z-40":
                  !isServer && !breakpoints.lg && override(),
              }}
            >
              <ul class="flex flex-col text-md divide-y divide-roma-grey px-7 lg:text-base lg:flex-row lg:justify-center items-center w-full h-full">
                <Index each={nav()}>
                  {(section, index) => (
                    <li
                      onMouseLeave={() => breakpoints.lg && closeNav()}
                      onMouseEnter={() => {
                        if (section().categories?.length != 0) {
                          closeNav.clear();
                          breakpoints.lg && openNav(index);
                        }
                      }}
                      class="h-full w-full lg:w-auto md:items-center"
                      classList={{ "lg:border-black": currentNav() == index }}
                    >
                      <A
                        onClick={() => closeNav()}
                        class="w-full py-4 flex items-center justify-between px-4 h-full lg:border-transparent hover:lg:border-black border-b-2 cursor-pointer lg:transition lg:duration-300"
                        activeClass="!border-gray-200"
                        href={section().url ? section().url! : ""}
                      >
                        <div class="font-medium">{section().label}</div>
                      </A>
                    </li>
                  )}
                </Index>
              </ul>
            </div>
            <div class="flex h-full justify-end col-span-6 lg:col-span-3">
              <div class="lg:flex hidden relative items-center ">
                <input
                  class=" bg-roma-grey placeholder:text-[#676767] pl-10 text-xs pr-2 py-2 rounded-full z-40 relative "
                  classList={{ hidden: !!params.search }}
                  placeholder="Search"
                  onFocus={() => setParams({ search: true })}
                  onClick={() => setParams({ search: true })}
                />

                <Icon
                  class="text-roma-dark-grey w-5 h-5 absolute left-3 z-40"
                  classList={{ hidden: !!params.search }}
                  stroke-width="2"
                  path={magnifyingGlass}
                />
              </div>
              <button
                aria-label="Search"
                class="lg:hidden flex items-center space-x-2 p-3 px-4 -mr-4"
                onClick={() => setParams({ search: true })}
                title="Click to search"
              >
                <Icon class="w-5" stroke-width="1.75" path={magnifyingGlass} />
              </button>
              <SidePanel />
              <Hamburger
                class="lg:hidden"
                opened={override}
                setOpened={() => setOverride(!override())}
              />
            </div>
          </nav>
          <div
            class="absolute backdrop-blur-xl bg-white/98 overflow-hidden w-full border-t transition-all duration-300 shadow z-30"
            style={{ height: currentNav() == null ? "0px" : `${height()}px` }}
            classList={{
              "border-t-gray-100": currentNav() !== null,
            }}
          >
            <Index each={props.structure}>
              {(section, index) => {
                return section().categories?.length === 0 ? (
                  <></>
                ) : (
                  <NavSection
                    active={() => currentNav() == index}
                    removeNav={removeNav}
                    closeNav={closeNav}
                    clearClose={closeNav.clear}
                    categories={section().categories}
                    setHeight={setHeight}
                  />
                );
              }}
            </Index>
          </div>
        </div>
        <Presence exitBeforeEnter>
          <Show when={params.search}>
            <HeaderSearch removeNav={removeNav} />
          </Show>
        </Presence>
        {/* <Presence exitBeforeEnter>
          <Show when={!!params.favourites || !!params.cart}>
            <Items
              close={() =>
                setParams({ favourites: undefined, cart: undefined })
              }
            />
          </Show>
        </Presence> */}

        <Show when={notifications.length > 0}>
          <div class="absolute bottom-0 translate-y-full inset-x-0 border-y border-white -z-[1]">
            <Motion.div
              class=" text-white  h-[3.25rem] text-sm"
              initial={{ opacity: 0, transform: "translateX(-100%)" }}
              animate={{ opacity: 1, transform: "translateX(0)" }}
              exit={{ opacity: 0, transform: "translateX(100%)" }}
            >
              <ErrorNotificationsContainer />
            </Motion.div>
          </div>
        </Show>
      </header>
    </>
  );
};

export default Nav;
